<template>
  <ClFormRow
    :label="label"
    :tooltip="tooltip"
    :name="name"
    :toolTipText="toolTipText"
    ref="select"
  >
    <template #content>
      <multiselect
        v-model="selectedOptions"
        :options="selectOptions"
        :taggable="taggable"
        @tag="addTag"
        :hide-selected="true"
        :block-keys="['Tab', 'Enter']"
        :multiple="multiple"
        :disabled="disabled"
        required
        :selectLabel="selectLabel"
        class="custom-multi-select zq--multiselect"
      >
        <template slot="option" slot-scope="props">
          <span>{{ getLabel(props.option) }}</span>
        </template>
        <template slot="singleLabel" slot-scope="props">
          <span>{{ getLabel(props.option) }}</span>
        </template>
        <template slot="tag" slot-scope="props">
          <span class="multiselect__tag">
            {{ getLabel(props.option) }}
            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="removeTag(props.option)" />
          </span>

        </template>
        <span v-show="taggable" slot="noOptions"></span>
      </multiselect>
    </template>
  </ClFormRow>
</template>

<script>
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import {startCase} from 'lodash';

export default {
  name: "ZqSelectField",
  components: {
    ClFormRow
  },
  props: {
    value: [Array, String],
    name: String,
    label: String,
    tooltip: String,
    toolTipText: String,
    selectLabel: String,
    taggable: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    options: Array,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedOptions: [],
      selectOptions: []
    }
  },
  created() {
    this.options.sort((a, b) => a.localeCompare(b))

    if (this.name === 'entityTypes' || this.name === 'appliesTo') {
      const excludeConnections = ['SqsConnection', 'KafkaConnection', 'RabbitMqConnection'];
      this.selectOptions = this.options.filter(item => !excludeConnections.includes(item));
      const idx = this.selectOptions.findIndex(field => field === 'Events');
      if (idx !== -1) {
        this.selectOptions[idx] = 'Event'
      }
    } else {
      this.selectOptions = this.options;
    }

    if (this.value) {
      this.selectedOptions = this.value
    }
  },
  methods: {
    getLabel(label) {
      if (Object.hasOwn(label, 'isTag')) {
        return label.label;
      }

      return startCase(label)
    },
    addTag(val) {
      this.selectedOptions = [...this.selectedOptions, val];
    },
    removeTag(val) {
      this.selectedOptions = this.selectedOptions.filter(item => item !== val)
    }
  },
  watch: {
    selectedOptions: {
      deep: true,
      handler(val) {
        if (val && val.length) {
          if (this.$refs.select.$el.classList.contains('zq-invalid')) {
            this.$refs.select.$el.classList.remove('zq-invalid')
          }
        }
        this.$emit('input', this.selectedOptions)
      },
    }
  }
}
</script>

<style scoped>

</style>